import { OrderedMap, Record } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { ABORTED, COMPLETED, PROCESSING, QUEUED, ERROR } from '../constants/resultState'

// ResultStepDetail

export const ResultStepDetail = Record({
  report: '',
  reportCss: null,
  reportJs: null
})

export const ResultStepDetailPropType = ImmutablePropTypes.recordOf({
  report: PropTypes.string.isRequired,
  reportCss: PropTypes.string,
  reportJs: PropTypes.string
})

export function objectToResultStepDetail (obj) {
  return ResultStepDetail(obj)
}

// ResultStep

export const ResultStep = Record({
  id: null,
  label: null,
  accepted: null,
  score: null,
  summary: null,
  details: null,
  secret: null,
  has_error: null
})

export const ResultStepPropType = ImmutablePropTypes.recordOf({
  id: PropTypes.string,
  label: PropTypes.string,
  accepted: PropTypes.bool,
  score: PropTypes.number,
  summary: PropTypes.string,
  details: ResultStepDetailPropType,
  secret: PropTypes.bool,
  has_error: PropTypes.bool
})

export const ResultStepsPropType = ImmutablePropTypes.orderedMapOf(
  ResultStepPropType,
  PropTypes.string.isRequired
)

export function objectToResultStep ({ score, details, ...rest }) {
  return ResultStep({
    ...rest,
    score: parseInt(score, 10) || null,
    details: details && objectToResultStepDetail(details)
  })
}

export function arrayToResultSteps (array) {
  return OrderedMap().withMutations(
    map => {
      array.forEach(
        step => map.set(step.id, objectToResultStep(step))
      )
    }
  )
}

// Result

export const Result = Record({
  id: null,
  updated: 0,
  assignment: null,
  error: null,
  state: null,
  progress: null,
  score: null,
  accepted: null,
  steps: null
})

export const ResultPropType = ImmutablePropTypes.recordOf({
  id: PropTypes.string,
  updated: PropTypes.number.isRequired,
  error: PropTypes.string,
  assignment: PropTypes.number,
  state: PropTypes.oneOf([QUEUED, PROCESSING, COMPLETED, ABORTED, ERROR]),
  progress: PropTypes.number,
  score: PropTypes.number,
  accepted: PropTypes.bool,
  steps: ResultStepsPropType
})

export function objectToResult ({ id = null, updated, assigment, progress, score, steps, ...rest }) {
  return Result({
    id,
    updated: parseInt(updated, 10) || null,
    assignment: parseInt(assigment, 10) || null,
    progress: parseInt(progress, 10) || null,
    score: parseFloat(score) || null,
    ...rest,
    steps: steps && arrayToResultSteps(steps)
  })
}

export function isProcessing (result) {
  const state = result?.get('state')
  return state === QUEUED || state === PROCESSING
}

export function isDone (result) {
  const state = result?.get('state')
  return state === COMPLETED
}


export function isLoaded (result) {
  // noinspection PointlessBooleanExpressionJS
  return isDone && !!(result.get('steps')?.size > 0)
}

export function isUnavailable (result) {
  const state = result?.get('state')
  return state === ABORTED || state === ERROR
}
