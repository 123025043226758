import { Map } from 'immutable'

import { RESTORE, UPDATE_CODE } from '../../constants/actiontypes'

import defaultCode from '../default/code'

export default function codeReducer (state = defaultCode, action) {
  switch (action.type) {
    case RESTORE:
      // Restore persisted state
      const { serialized } = action
      if (serialized.$codeVersion === 1) {
        return Map(serialized.code)
      }
      break

    case UPDATE_CODE:
      const { type, ...data } = action
      return state.merge(Map(data))
  }

  return state
}
