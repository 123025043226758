import { Map, Set } from 'immutable'

const getHelp = state => (state && state.get('help')) || Map()

const getHelpTexts = state => getHelp(state).get('text') || Map()
export const getHelpText = (state, props) => getHelpTexts(state).get(props.section)

const getSeen = state => getHelp(state).get('seen') || Set()
export const hasSeenHelp = (state, props) => getSeen(state).has(props.section)

const getAvailable = state => getHelp(state).get('available') || Set()
export const haveHelpSection = (state, props) => getAvailable(state).has(props.section)
