import { is, isImmutable, Map } from 'immutable'

import assignmentsReducer from './assignments'
import authReducer from './auth'
import codeReducer from './code'
import helpTextReducer from './help'
import selectionReducer from './selection'
import resultReducer from './result'
import feedbackReducer from './feedback'
import waffleReducer from './waffle'
import messagesReducer from './messages'
import memoReducer from './memo'
import tagsReducer from './tags'

export default function rootReducer (state = Map(), action) {
  if (!isImmutable(state)) {
    state = Map(state)
  }
  return state.withMutations(mutableState => {
    function reduce (key, reducer) {
      const prevState = mutableState.get(key)
      const nextState = reducer(prevState, action)
      if (!is(prevState, nextState)) {
        mutableState = mutableState.set(key, nextState)
        return nextState
      }
      return prevState
    }

    reduce('selection', selectionReducer)
    reduce('code', codeReducer)
    reduce('result', resultReducer)
    reduce('feedback', feedbackReducer)
    reduce('waffle', waffleReducer)
    reduce('auth', authReducer)
    reduce('messages', messagesReducer)
    reduce('memo', memoReducer)
    reduce('assignments', assignmentsReducer)
    reduce('help', helpTextReducer)
    reduce('tags', tagsReducer)

    return mutableState
  })
}
