import { Map } from 'immutable'

import { DELETE_MEMO, RESTORE, SET_MEMO } from '../../constants/actiontypes'

export default function memoReducer (state = Map(), action) {
  switch (action.type) {
    case RESTORE: {
      // Restore persisted state
      const { serialized } = action
      if (serialized.$memoVersion === 1) {
        return Map(serialized.memo)
      }
      break
    }

    case SET_MEMO: {
      const { key, value } = action
      return state.set(key, value)
    }

    case DELETE_MEMO: {
      const { key } = action
      return state.remove(key)
    }
  }

  return state
}
