import { Set } from 'immutable'

import { RESTORE, MARK_FEEDBACK_DONE } from '../../constants/actiontypes'

export default function feedbackReducer (state = Set(), action) {
  switch (action.type) {
    case RESTORE:
      // Restore persisted state
      const { serialized } = action
      if (serialized.$feedbackVersion === 1) {
        return Set(serialized.feedback)
      }
      break

    case MARK_FEEDBACK_DONE:
      return state.add(parseInt(action.assignmentId, 10))
  }

  return state
}
