export const RESTORE = 'RESTORE'

export const SELECT_NEEDS = 'SELECT_NEEDS'
export const SELECT_EXCLUDES = 'SELECT_EXCLUDES'
export const SELECT_ASSIGNMENT = 'SELECT_ASSIGNMENT'
export const ADD_NEEDED = 'ADD_NEEDED'
export const ADD_EXCLUDED = 'ADD_EXCLUDED'
export const REMOVE_NEEDED = 'REMOVE_NEEDED'
export const REMOVE_EXCLUDED = 'REMOVE_EXCLUDED'

export const UPDATE_CODE = 'UPDATE_CODE'

export const UPDATE_RESULT = 'UPDATE_RESULT'
export const UPDATE_RESULT_FAILURE = 'UPDATE_RESULT_FAILURE'
export const UPDATE_RESULT_STEPS = 'UPDATE_RESULT_STEPS'
export const UPDATE_RESULT_STEP_DETAIL = 'UPDATE_RESULT_STEP_DETAIL'

export const MARK_FEEDBACK_DONE = 'MARK_FEEDBACK_DONE'

export const FINISH_ASSIGNMENT = 'FINISH_ASSIGNMENT'

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

export const SET_MEMO = 'SET_MEMO'
export const DELETE_MEMO = 'DELETE_MEMO'

export const FETCH_ASSIGNMENT_INFO = 'FETCH_ASSIGNMENT_INFO'

export const FETCH_HELP_TEXT = 'FETCH_HELP_TEXT'
export const MARK_HELP_SEEN = 'MARK_HELP_SEEN'

export const FETCH_TAGS = 'FETCH_TAGS'
export const FETCHING_TAGS = 'FETCHING_TAGS'
