import { Map } from 'immutable'

import { throttle } from 'lodash'

import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducers'
import { rootSerializer, VERSION as SERIAL_VERSION } from './serialize'

import { RESTORE } from '../constants/actiontypes'
import initialState from './default/initialState'

const STATE_KEY = 'state'

function getUid () {
  return initialState.auth.uid ?? ''
}

// function migrateState (state) {
//   if (state.$version === 1) {
//     state.$version = 2
//     state.$uid = getUid()
//   }
//
//   return state
// }

function setupPersistence (store) {
  try {
    const persistedState = localStorage.getItem(STATE_KEY)
    if (persistedState) {
      const state = JSON.parse(persistedState)
      // if (state.$version < SERIAL_VERSION) {
      //   migrateState(state)
      // }
      if (state.$version === SERIAL_VERSION && (!state.$uid || state.$uid === getUid())) {
        store.dispatch({
          type: RESTORE,
          serialized: state
        })
      } else {
        localStorage.removeItem(STATE_KEY)
      }
    }
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') throw (err)
  }

  store.subscribe(throttle(() => {
    try {
      const persistedState = JSON.stringify(rootSerializer(store.getState(), getUid()))
      localStorage.setItem(STATE_KEY, persistedState)
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') throw (err)
    }
  }, 1000))
}

export default function configureStore () {
  const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const store = createStore(
    rootReducer,
    Map(),
    composeEnhancers(
      applyMiddleware(
        thunkMiddleware
      )
    )
  )

  if (localStorage) {
    setupPersistence(store)
  }

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () =>
      store.replaceReducer(require('./reducers').default)
    )
  }

  return store
}
