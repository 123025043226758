import { Urls } from 'django_reverse_js'

import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { AppBar, Button, IconButton, Link, Menu, MenuItem, Slide, Toolbar, useScrollTrigger } from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { bindTrigger, bindMenu, usePopupState } from 'material-ui-popup-state/hooks'

import useStartUrl from '../hooks/useStartUrl'

import { getIsAuthenticated, getAuthEmail, getAuthStaff } from '../state/selectors/auth'

import Logo from './Logo'

function HideOnScroll (props) {
  const { children } = props
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

if (process.env.NODE_ENV !== 'production') {
  HideOnScroll.displayName = 'HideOnScroll'
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired
  }
}

const UserState = () => {
  const authenticated = useSelector(getIsAuthenticated)
  const email = useSelector(getAuthEmail)
  const staff = useSelector(getAuthStaff)
  const popupState = usePopupState({ variant: 'popover', popupId: 'accountMenu' })
  const closeMenu = useCallback(
    () => popupState.close(),
    [popupState]
  )

  if (authenticated) {
    return (
      <div>
        {email ? (email + ' ') : null}
        <IconButton
          {...bindTrigger(popupState)}
          aria-label="account of current user"
          aria-haspopup="true"
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem component={RouterLink} to={Urls.profile()} onClick={closeMenu}>Profile</MenuItem>
          <MenuItem component="a" href={Urls.logout()}>Log out</MenuItem>
          {staff && <MenuItem component="a" href={Urls['admin:index']()}>Configure site</MenuItem>}
        </Menu>
      </div>
    )
  } else {
    return <Button component={RouterLink} to={Urls.login()} color="inherit">Login</Button>
  }
}

if (process.env.NODE_ENV !== 'production') {
  UserState.displayName = 'UserState'
  // UserState.propTypes = {}
}

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1
  },
  logo: {
    position: 'absolute',
    margin: 18,
    top: 0,
    left: 0,
    height: 28,
    width: 100
  }
}))

const Nav = () => {
  const classes = useStyles()
  const startUrl = useStartUrl()

  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed">
          <Toolbar>
            <Link component={RouterLink} to={startUrl} variant='h6' color='inherit' className={classes.title}>
              <Logo className={classes.logo} variant='white' />
            </Link>
            <UserState/>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar/>
    </>
  )
}

if (process.env.NODE_ENV !== 'production') {
  Nav.displayName = 'Nav'
  // Nav.propTypes = {}
}

export default Nav
