import { fromJS } from 'immutable'

import initialState from '../default/initialState'

export default function waffleReducer (state, action) {
  if (!state) {
    return fromJS(initialState.waffle)
  }
  return state
}
