import PropTypes from 'prop-types'
import React, { Component } from 'react'
// import ReactGA from 'react-ga'

class ErrorBoundary extends Component {
  static propTypes = (process.env.NODE_ENV !== 'production') ? {
    children: PropTypes.node.isRequired
  } : undefined

  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, info) {
    console.log(error, info)
    // ReactGA.exception({
    //   description: error.message,
    //   fatal: true
    // })
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
