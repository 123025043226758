import { Urls } from 'django_reverse_js'

import { createBrowserHistory } from 'history'

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router'

import { CircularProgress } from '@material-ui/core'

import Footer from '../components/Footer'
import Nav from '../components/Nav'

import useStartUrl from '../hooks/useStartUrl'
import useStateHistory from '../hooks/useStateHistory'

import { getNeedLogin } from '../state/selectors/auth'

const Login = React.lazy(() => import('./Login'))
const TagBrowser = React.lazy(() => import('./TagBrowser'))
const Main = React.lazy(() => import('./Main'))
const Registration = React.lazy(() => import('./Registration'))
const AccountRecovery = React.lazy(() => import('./AccountRecovery'))
const EmailValidation = React.lazy(() => import('./EmailValidation'))
const Profile = React.lazy(() => import('./Profile'))
const Policy = React.lazy(() => import('./Policy'))

const main = stage => routeProps => <Main stage={stage} {...routeProps} />

const Home = () => {
  const needLogin = useSelector(getNeedLogin)
  const startUrl = useStartUrl()
  const history = useStateHistory()
  const url = needLogin ? Urls.login() : startUrl

  useEffect(
    () => {
      history.replace(url)
    },
    [history, url]
  )
  return null
}

const Routes = () => (
  <Router history={createBrowserHistory()}>
    <Nav/>
    <React.Suspense fallback={<CircularProgress/>}>
      <Switch>
        <Route path={Urls.login()} component={Login}/>
        <Route path={Urls.register()} component={Registration}/>
        <Route path={Urls.recovery()} component={AccountRecovery}/>
        <Route path={Urls.email_validation()} component={EmailValidation}/>
        <Route path={Urls.tag_graph_empty()} exact component={TagBrowser}/>
        <Route path={Urls.tag_graph(':ids')} component={TagBrowser}/>
        <Route path={Urls.policy()} component={Policy}/>
        <Route path={Urls.about()} render={main('about')}/>
        <Route path={Urls.find()} render={main('find')}/>
        <Route path={Urls.edit(':id')} render={main('edit')}/>
        <Route path={Urls.check(':id')} render={main('check')}/>
        <Route path={Urls.profile()} component={Profile}/>
        <Route from={Urls.home()} exact component={Home}/>
      </Switch>
      <Footer/>
    </React.Suspense>
  </Router>
)

export default Routes
