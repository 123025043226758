import {
  RESTORE,
  UPDATE_CODE,
  UPDATE_RESULT,
  UPDATE_RESULT_FAILURE,
  UPDATE_RESULT_STEP_DETAIL,
  UPDATE_RESULT_STEPS
} from '../../constants/actiontypes'
import { arrayToResultSteps, objectToResult, objectToResultStep, Result } from '../../types/result'
import { ERROR } from '../../constants/resultState'

export default function resultReducer (state = Result(), action) {
  switch (action.type) {
    case RESTORE:
      // Restore persisted state
      const { serialized } = action
      if (serialized.$resultVersion === 1) {
        return objectToResult(serialized.result)
      }
      break

    case UPDATE_RESULT:
      return objectToResult({
        updated: action.updated,
        ...action.data
      })

    case UPDATE_RESULT_FAILURE:
      return Result({
        assignment: action.assignment,
        updated: action.updated,
        state: ERROR,
        error: (action.status === 400 && action.data && action.data['non_field_errors']) || null
      })

    case UPDATE_RESULT_STEPS:
      return state.set('steps', arrayToResultSteps(action.data))

    case UPDATE_RESULT_STEP_DETAIL:
      const steps = state.get('steps')
      return state.set('steps', steps.set(action.data.id, objectToResultStep(action.data)))

    case UPDATE_CODE:
      // If the code is updated for the same assignment as the current result, clear the result
      if (action.assignment === state.get('assignment')) {
        return Result()
      }
      break
  }

  return state
}
