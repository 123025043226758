import serializeCode from './code'
import serializeResult from './result'
import serializeSelection from './selection'
import serializeFeedback from './feedback'
import serializeMemo from './memo'
import serializeHelp from './help'

export const VERSION = 1

export function rootSerializer (state, uid) {
  let serialized = {
    $version: VERSION,
    $uid: uid
  }

  function serialize (key, serializer) {
    serialized = {
      ...serialized,
      ...serializer(state.get(key))
    }
  }

  serialize('selection', serializeSelection)
  serialize('code', serializeCode)
  serialize('result', serializeResult)
  serialize('feedback', serializeFeedback)
  serialize('memo', serializeMemo)
  serialize('help', serializeHelp)

  return serialized
}
