import 'abort-controller/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { setConfig } from 'react-hot-loader'

import Root from './containers/Root'
import updateBodyClass from './functions/updateBodyClass'
import configureStore from './state/configureStore'

setConfig({
  hotHooks: true
})

updateBodyClass()

const store = configureStore()

ReactDOM.render(
  <Root store={store}/>,
  document.getElementById('root')
)
