import { Map, OrderedSet } from 'immutable'

const defaultTags = Map({
  loading: false,
  loaded: false,
  tags: OrderedSet(),
  byId: Map()
})

export default defaultTags
