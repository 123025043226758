import { Map } from 'immutable'

import { FETCH_TAGS, FETCHING_TAGS } from '../../constants/actiontypes'

import defaultTags from '../default/tags'

export default function tagsReducer (state = defaultTags, action) {
  switch (action.type) {
    case FETCHING_TAGS:
      return state.set('loading', true)

    case FETCH_TAGS: {
      const tags = action.tags
      return state.merge({
        loading: false,
        loaded: true,
        tags,
        byId: Map().withMutations(map => {
          tags.forEach(tag => { map = map.set(tag.id, tag) })
        })
      })
    }
  }

  return state
}
