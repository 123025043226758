import { Set, fromJS } from 'immutable'

import initialState from '../default/initialState'

export default function authReducer (state, action) {
  if (!state) {
    const { auth } = initialState
    return {
      backends: fromJS(auth.backends),
      samlidps: fromJS(auth.samlidps),
      email: auth.email ?? null,
      serfId: auth.serfId ?? null,
      staff: auth.staff ?? false,
      providers: new Set(auth.providers),
      registration: auth.registration ?? false,
      uid: auth.uid || '',
      needLogin: auth.needLogin ?? 'always',
      authenticated: auth.authenticated ?? false,
    }
  }
  return state
}
