import { Map, Set } from 'immutable'

import { FETCH_HELP_TEXT, MARK_HELP_SEEN, RESTORE } from '../../constants/actiontypes'

import initialState from '../default/initialState'

export default function helpTextReducer (state, action) {
  if (state === undefined) {
    state = Map({
      available: Set(initialState.helpSections),
      text: Map(),
      seen: Set()
    })
  }

  switch (action.type) {
    case RESTORE: {
      // Restore persisted state
      const { serialized } = action
      if (serialized.$helpVersion === 1) {
        return state.set('seen', Set(serialized.help.seen))
      }
      break
    }

    case FETCH_HELP_TEXT:
      return state.update('text', text => text.set(action.id, action.text))

    case MARK_HELP_SEEN:
      return state.update('seen', set => set.add(action.id))
  }

  return state
}
