export const getAuth = state => (state && state.get('auth')) || {}
export const getAuthBackends = state => getAuth(state).backends
export const getAuthSAMLIdPs = state => getAuth(state).samlidps
export const getAuthEmail = state => getAuth(state).email
export const getAuthSerfId = state => getAuth(state).serfId
export const getAuthStaff = state => getAuth(state).staff
export const getAuthProviders = state => getAuth(state).providers
export const getAuthRegistration = state => getAuth(state).registration
export const getNeedLogin = state => getAuth(state).needLogin
export const getIsAuthenticated = state => getAuth(state).authenticated
export const getUid = state => getAuth(state).uid
