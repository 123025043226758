import qs from 'qs'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'

export default function useStateHistory (
  defaultParams = undefined,
  requiredParams = { qn: undefined, qe: undefined }
) {
  // A version if useHistory() that preserves most query parameters.
  // The parameters in `defaultParams` are added to the query if they do not already exist
  // The parameters in `requiredParams` are always added to the query, overwriting any existing value.
  // If any parameter is `undefined` it will be removed from the query
  // Default version of this hook will remove the `qn` and `qe` parameters and retain the rest

  const history = useHistory()
  const location = useLocation()

  const makeUrl = useCallback(
    (url, params) => {
      const newSearch = qs.stringify({
        ...defaultParams,
        ...qs.parse(location.search, { ignoreQueryPrefix: true }),
        ...requiredParams,
        ...params
      }, { addQueryPrefix: true })
      return `${url}${location.hash}${newSearch}`
    },
    [defaultParams, location.hash, location.search, requiredParams]
  )

  const push = useCallback(
    (url, params, state) => {
      url && history.push(makeUrl(url, params), state)
    },
    [history, makeUrl]
  )

  const replace = useCallback(
    (url, params, state) => {
      url && history.replace(makeUrl(url, params), state)
    },
    [history, makeUrl]
  )

  return {
    ...history,
    push,
    replace
  }
}
