import { createMuiTheme } from '@material-ui/core/styles'

import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'

export default createMuiTheme({
  palette: {
    accepted: {
      light: green[300],
      main: green[500],
      dark: green[700]
    },
    rejected: {
      light: red[300],
      main: red[500],
      dark: red[700]
    }
  }
})
