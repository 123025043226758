import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createGenerateClassName, withStyles, StylesProvider } from '@material-ui/styles'

import { hot } from 'react-hot-loader/root'

import theme from '../constants/theme'

import PropTypes from 'prop-types'

import React from 'react'
import ErrorBoundary from '../components/ErrorBoundary'
import { Provider } from 'react-redux'

import Routes from './Routes'
import Box from '../components/Box'

const styles = theme => ({
  '@global': {
    'body.standalone': {
      margin: theme.spacing(2)
    },
    '#root': {
      height: '100%'
    }
  }
})

const InnerRoot = ({ store }) => (
  <ErrorBoundary>
    <CssBaseline />
    <Box height='100vh' flexGrow={1}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </Box>
  </ErrorBoundary>
)

if (process.env.NODE_ENV !== 'production') {
  InnerRoot.displayName = 'InnerRoot'
  InnerRoot.propTypes = {
    store: PropTypes.object.isRequired
  }
}

const StyledInnerRoot = withStyles(styles, { withTheme: true })(InnerRoot)

const generateClassName = createGenerateClassName({
  productionPrefix: 'c'
})

const Root = ({ store }) => (
  <StylesProvider generateClassName={generateClassName}>
    <MuiThemeProvider theme={theme}>
      <StyledInnerRoot store={store} history={history} />
    </MuiThemeProvider>
  </StylesProvider>
)

if (process.env.NODE_ENV !== 'production') {
  Root.displayName = 'Root'
  Root.propTypes = {
    store: PropTypes.object.isRequired
  }
}

export default hot(Root)
