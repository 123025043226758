import {
  borders,
  compose,
  css,
  display,
  flexbox,
  palette,
  positions,
  sizing,
  spacing,
  typography
} from '@material-ui/system'
import { styled } from '@material-ui/styles'

export const styleFunction = css(
  compose(
    borders,
    display,
    flexbox,
    positions,
    palette,
    // shadows,
    sizing,
    spacing,
    typography
  )
)

const Box = styled('div')(styleFunction, {
  name: 'Box'
})

export default Box
