export default function updateBodyClass () {
  if (window.top == window.self) {
    // Running stand-alone - add 'standalone' class to <body>
    const body = document.body
    let className = body.className

    if (className) {
      className = className.concat(' standalone')
    } else {
      className = 'standalone'
    }

    body.className = className
  }
}
