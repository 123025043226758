import { Urls } from 'django_reverse_js'

import { useSelector } from 'react-redux'
import { hasSeenHelp, haveHelpSection } from '../state/selectors/help'

export default function useStartUrl () {
  const hasAboutHelp = useSelector(state => haveHelpSection(state, { section: 'about' }))
  const seenHelp = useSelector(state => hasSeenHelp(state, { section: 'about' }))

  return ((!hasAboutHelp || seenHelp) ? Urls.find() : Urls.about())
}
