import React from 'react'

import propTypes from 'prop-types'

import LogoWhite from '../assets/logo-white.svg'
import LogoBlue from '../assets/logo-blue.svg'

const variants = {
  white: LogoWhite,
  blue: LogoBlue
}

const Logo = ({ variant = 'blue', alt = 'SERF', ...props }) => <img src={variants[variant]} alt={alt} {...props} />

if (process.env.NODE_ENV !== 'production') {
  Logo.displayName = 'Logo'
  Logo.propTypes = {
    variant: propTypes.string,
    alt: propTypes.string
  }
}

export default Logo
