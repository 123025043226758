import { Urls } from 'django_reverse_js'

import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { haveHelpSection } from '../state/selectors/help'

const useStyles = makeStyles(theme => ({
  footer: {
    margin: [[0, 'auto']],
    padding: [[theme.spacing(3), 0]],
    textAlign: 'center'
  }
}))

const Footer = React.memo(() => {
  const classes = useStyles()
  const hasAbout = useSelector(state => haveHelpSection(state, { section: 'about' }))
  const hasPolicy = useSelector(state => haveHelpSection(state, { section: 'policy' }))
  const sep = <span> &middot; </span>

  return (
    <footer className={classes.footer}>
      &copy; SERF
      { hasAbout &&
        <>
          { sep }
          <Link key="about" component={RouterLink} to={Urls.about()}>
            About SERF
          </Link>
        </>
      }
      { hasPolicy &&
        <>
          { sep }
          <Link key="policy" component={RouterLink} to={Urls.policy()}>
            Privacy Policy
          </Link>
        </>
      }
    </footer>
  )
})

if (process.env.NODE_ENV !== 'production') {
  Footer.displayName = 'Footer'
}

export default Footer
