import { Map, OrderedSet } from 'immutable'

import {
  FINISH_ASSIGNMENT,
  RESTORE,
  SELECT_ASSIGNMENT,
  SELECT_EXCLUDES,
  SELECT_NEEDS,
  ADD_NEEDED, ADD_EXCLUDED,
  REMOVE_NEEDED, REMOVE_EXCLUDED,
} from '../../constants/actiontypes'

import defaultSelection from '../default/selection'

export default function selectionReducer (state = defaultSelection, action) {
  switch (action.type) {
    case RESTORE:
      // Restore persisted state
      if (action.serialized.$selectionVersion === 2) {
        const { selection: serializedSelection } = action.serialized
        return Map({
          needs: OrderedSet(serializedSelection.needs),
          excludes: OrderedSet(serializedSelection.excludes),
          assignment: serializedSelection.assignment
        })
      }
      break

    // Needs
    case SELECT_NEEDS:
      return state.merge({
        needs: action.ids,
        excludes: state.get('excludes').subtract(action.ids)
      })

    case ADD_NEEDED:
      return state.merge({
        needs: state.get('needs').add(action.id),
        excludes: state.get('excludes').remove(action.id)
      })

    case REMOVE_NEEDED:
      return state.merge({
        needs: state.get('needs').remove(action.id)
      })

    // Excludes
    case SELECT_EXCLUDES:
      return state.merge({
        excludes: action.ids,
        needs: state.get('needs').subtract(action.ids)
      })

    case ADD_EXCLUDED:
      return state.merge({
        excludes: state.get('excludes').add(action.id),
        needs: state.get('needs').remove(action.id)
      })

    case REMOVE_EXCLUDED:
      return state.merge({
        excludes: state.get('excludes').remove(action.id)
      })

    // Assignment
    case SELECT_ASSIGNMENT:
      return state.merge({
        assignment: action.id
      })

    case FINISH_ASSIGNMENT:
      return defaultSelection
  }

  return state
}
