import { List, Record, Set } from 'immutable'

import initialState from '../default/initialState'
import { CLEAR_MESSAGES } from '../../constants/actiontypes'

const makeMessage = Record({
  message: null,
  tags: Set()
})

export default function messagesReducer (state, action) {
  if (state === undefined) {
    state = List()
    const { messages } = initialState
    if (messages) {
      state = state.withMutations(list => {
        for (const message of messages) {
          list.push(makeMessage({
            message: message.message,
            tags: Set(message.tags)
          }))
        }
      })
    }
  }

  if (action.type === CLEAR_MESSAGES) {
    const { tags } = action
    state = tags ? state.filter(message => !tags.isSubset(message.tags)) : List()
  }

  return state
}
