import { Map } from 'immutable'

import { FETCH_ASSIGNMENT_INFO, FINISH_ASSIGNMENT } from '../../constants/actiontypes'

export default function assignmentsReducer (state = Map(), action) {
  switch (action.type) {
    case FETCH_ASSIGNMENT_INFO:
      return state.set(action.id, action.info)
    case FINISH_ASSIGNMENT:
      return Map()
  }

  return state
}
